// import PluginChatMess from "@/common/components/Chat/PluginChatMess";
// import { PageTransition } from "@/common/Layout/PageTransition";
import "@/styles/embla.css";
import "@/styles/globals.css";
import "@/styles/webview.css";
import "setimmediate";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
// import "tocbot/src/scss/tocbot.scss";
import "tocbot/static/css/tocbot.css";
import React, { useEffect } from "react";

import Layout from "common/Layout";
import { DefaultSeo } from "next-seo";
import SEO from "next-seo.config";
import CartProvider from "setup/cartProvider";
import ThemeProvider from "setup/mantine-provider";
import ReactQueryProvider from "setup/react-query";

import Transition from "@/common/Layout/Transition";
import APIProvider from "@/setup/flexbiz-api-provider/index";
import { LocaleProvider } from "@/setup/locale-provider";

function MyApp({ Component, pageProps, router }) {
  const getLayout = Component.getLayout || ((page) => <Layout>{page}</Layout>);


  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init("2475782152521154"); // facebookPixelId
        ReactPixel.pageView();
        router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView();
        });
      });
  }, [router.events]);

  return (
    <ReactQueryProvider pageProps={pageProps}>
      <ThemeProvider>
        <APIProvider>
          <LocaleProvider>
            <CartProvider>
              <DefaultSeo {...SEO} />
              <Transition location={router.asPath}>
                {getLayout(<Component {...pageProps} />)}
              </Transition>
            </CartProvider>
          </LocaleProvider>
        </APIProvider>
      </ThemeProvider>
    </ReactQueryProvider>
  );
}

export default MyApp;
