import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  container: {
    width: "100%",
    // borderBottom: "1px solid #000",
    boxShadow: "0 0 10px rgb(0 0 0 / 10%)",
    background: "#fff",

    color: "#666",
    fontSize: "12px",
    lineHeight: "24px",
    fontWeight: "400",

    position: "relative",
    zIndex: 10,
  },
  wrapper: {
    display: "flex",
    // flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fff",

    height: "100px",
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      height: "50px",
    },
  },
  burgerWrapper: {
    display: "none",

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      display: "block",
    },
  },
  logoWrapper: {
    position: "relative",
    aspectRatio: "1.6",
    height: "100px",
    transition: "1s",
    // width: "122px",
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      height: "40px",
    },
    "&:hover": {
      filter: "brightness(140%)",
    },
  },
  authWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      display: "none",
      // "& > *": {},
    },
  },
  cartWrapper: {
    display: "none",
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      display: "flex",
    },
  },
  rightSide: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      display: "none",
      // "& > *": {},
    },
  },
  mainMenu: {
    display: "flex",
    flexWrap: "wrap",

    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      display: "none",
    },
  },
  menuItem: {
    padding: "8px 0",
    // marginRight: 20,

    fontSize: 14,
    textTransform: "uppercase",
    color: theme.colors.second[0],
    transition: "0.3s",

    "&:hover": {
      color: theme.colors.second[3],
    },
  },
  optionsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  // subMenu
  subMenu: {
    position: "absolute",
    zIndex: 9990,
    bottom: "0",
    left: "0",
    background: "#fff",
    width: "100%",
    aspectRatio: "6",
    // padding: "15px 0",
    color: theme.colors.second[1],

    // display: "none",
    // opacity: 0,
    transition: "0.3s",
    transform: "translate(0, -10%)",
  },
  subMenuItem: {
    transition: "0.3s",
    color: theme.colors.second[0],

    "&:hover": {
      color: "#EBBC1D",
    },
  },

  verticalDivider: {
    display: "inline-flex",
    margin: "2px 8px 0",
    borderLeft: "1px solid #ccc",
    height: "20px",
  },

  authItem: {
    color: "#000",
    cursor: "pointer",
    "&:hover": {
      color: "#EBBC1D",
    },
  },
}));

export default useStyles;
