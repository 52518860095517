export default {
  menu: {
    about: {
      title: "Giới thiệu",
    },
    products: {
      title: "Sản phẩm",
    },
    promotion: {
      title: "Ưu đãi",
    },
    news: {
      title: "Thư viện",
    },
    contact: {
      title: "Liên hệ",
    },
    search: {
      title: "Tìm kiếm...",
    },
    profile: {
      title: "Hồ sơ",
    },
    store: {
      title: "Cửa hàng",
    },
  },
  miniCart: {
    titleA: "Bạn có ",
    titleB: " sản phẩm trong giỏ",
    statusStock: "Đang có hàng",
    labelTotal: "Tổng ước tính",
    btnCart: "Giỏ hàng",
    btnPay: "Thanh toán",
  },
  profile: {
    manager: {
      home: "Trang chủ",
      title: "Quản lý tài khoản",
      info: "Thông tin tài khoản",
      info_dcr: "Quản lý thông tin tài khoản cá nhân của bạn",
      address: "Sổ địa chỉ",
      address_dcr: "Quản lý địa chỉ giao hàng, thanh toán của bạn",
      purchase: "Đơn hàng đã mua",
      purchase_dcr: "Quản lý các đơn hàng mà bạn đã mua hoặc trả lại",
      liked: "Sản phẩm yêu thích",
      liked_dcr: "Quản lý danh sách các sản phẩm yêu thích",
      adjust: "Tuỳ chọn",
      logout: "Đăng xuất",
      register: "Đăng ký",
      login: "Đăng nhập",
    },
    layout: {
      home: "Trang chủ",
      title: "Quản lý tài khoản",
      info: "Thông tin tài khoản",
      address: "Sổ địa chỉ",
      purchase: "Đơn hàng đã mua",
      liked: "Sản phẩm yêu thích",
    },
    info: {
      info: "Thông tin tài khoản",
      account: "Tên đăng nhập",
      name: "Họ và tên",
      date: "Ngày tháng năm sinh",
      sex: "Giới tính",
      male: "Name",
      female: "Nữ",
      pass: "Mật khẩu",
      changePass: "Đổi mật khẩu",
      confirm: "Xác nhận thay đổi thông tin",
      currPass: "Mật khẩu hiện tại",
      newPass: "Mật khẩu mới",
      conPass: "Xác nhận mật khẩu mới",
      valPass: "Tối thiểu 6 ký tự bao gồm chữ hoa, chữ thường và số",
      noti: "Cập nhật thành công",
      notiFail: "Cập nhật không thành công",
      valName: "Vui lòng nhập đầy đủ họ và tên",
    },
    address: {
      title: "Sổ địa chỉ",
      default: " - Địa chỉ mặc định",
      addAddress: "Thêm địa chỉ",
      creatAddress: "Tạo mới sổ địa chỉ",
      changeAddress: "Chỉnh sửa",
      deleteAddress: "Xoá",
      deleteNoti: "Đã xoá địa chỉ",
      province: "Tỉnh thành phố",
      district: "Quận, huyện",
      ward: "Phường xã",
      slProvince: "Chọn tỉnh thành phố",
      slDistrict: "Chọn quận, huyện",
      slWard: "Chọn phường xã",
      setDF: "Đặt làm địa chỉ mặc định",
      save: "Lưu địa chỉ",
      changeNoti: "Thông tin đang được cập nhật",
    },
    order: {
      order: "Quản lý đơn hàng",
      title: "Danh sách đơn hàng đã mua",
      date: "Ngày đặt hàng",
      datepredict: "Ngày giao hàng dự kiến",
      id: "Mã đơn hàng",
      product: "Sản phẩm",
      quantity: "Số lượng",
      total: "Tổng tiền",
      orderDetail: "Chi tiết đơn hàng",
      name: "Tên người nhận",
      phone: "Số điện thoại",
      address: "Địa chỉ người nhận",
      detail: "Chi tiết",
      unitPrice: "Đơn giá",
      price: "Thành tiền",
      temp: "Tạm tính",
      ship: "Phí giao hàng",
      freeship: "Tiền miễn phí vận chuyển",
      cancelOrder: "Huỷ đơn",
      cancelReason: "Lý do huỷ đơn",
      cancel_dcr: "Lý do huỷ đơn của bạn là: ",
      confirm: "Xác nhận",
      cancel: "Huỷ",
      pay: "Tiếp tục thanh toán",
      rebuy: "Đặt lại",
      cancelNoti_suc: "Đã huỷ đơn",
      cancelNoti_fail: "Huỷ đơn không thành công",
      cancelNoti_val: "Vui lòng nhập đủ kí tự( >5)",
      rebuyNoti_suc: "Thêm vào giỏ hàng thành công",
      rebuyNoti_val: "Yêu cầu đang được xử lý",
    },
    liked: {
      title: "Sản phẩm yêu thích",
      noliked: "Bạn chưa có sản phẩm yêu thích",
    },
  },
  menuAuth: {
    account: "Tài khoản",
    profile: "Hồ sơ",
    address: "Sổ địa chỉ",
    order: "Đơn hàng",
    liked: "Sản phẩm yêu thích",
    option: "Tùy chọn",
    logout: "Đăng xuất",
  },
  homeLiked: {
    title: "Sản phẩm của chúng tôi",
  },
  homePromotion: {
    title: "Ưu đãi",
  },
  homeNews: {
    title: "Tin tức mới nhất",
  },
  subscribe: {
    title: "Đăng ký để nhận thông tin từ ZeMor Coffee",
    placeholder: "Nhập email của bạn",
    button: "Đăng ký",
    alertSuccess: "Đăng ký nhận tin tức thành công",
    alertFailed: "Đăng ký nhận tin tức không thành công",
  },
  footer: {
    addressLabel: "Địa chỉ liên hệ",
    customerPolicy: "Chính sách khách hàng",
    contactInfo: "Thông tin liên hệ",
    followUs: "Theo dõi chúng tôi",
    payments: "Phương thức thanh toán",
    cod: "Thanh toán trực tiếp",
    bankTransfer: "Chuyển khoản",
    wallet: "Ví điện tử",
    info1: "GIấy CNĐKKD: 0305693542 - Ngày cấp 22/02/2008",
    info2: "Cơ quan cấp: Sở Kế Hoạch và Đầu Tư TP HCM.",
  },
  contact: {
    taxCodeLabel: "Mã số thuế",
    email: "Email",
    address: "Văn phòng",
    form: {
      title: "Liên hệ với chúng tôi",
      fullName: "Họ và tên",
      email: "Email",
      phone: "Số điện thoại",
      address: "Địa chỉ",
      content: "Nội dung",
      button: "Gửi thông tin",
    },
    placeholder: {
      fullName: "Họ và tên",
      email: "Địa chỉ email",
      phone: "Số điện thoại của quý khách",
      content: "Nhập nội dung ở đây...",
      address: "Địa chỉ của quý khách",
    },
    map: {
      title: "Danh sách nhà máy ZeMor Coffee",
    },
  },
  collections: {
    filterCategory: "Danh mục",
    filterPrice: "Giá",
    remove: "Xóa",
    from: "Từ",
    to: "Đến",
    establish: "Thiết lập",
    searchResult: "Kết quả tìm kiếm cho: ",
    notfound: "Không tìm thấy sản phẩm",
    notFoundPromotion: "Hiện chưa có sản phẩm khuyến mãi",
    filter: "Lọc",
    reset: "Đặt lại",
    apply: "Áp dụng",
    sort: "SẮP XẾP THEO: ",
  },
  login: {
    seoTitle: "Đăng nhập - ZeMor Coffee",
    title: "Đăng nhập tài khoản",
    username: "Email hoặc số điện thoại",
    password: "Mật khẩu",
    forgotPass: "Quên mật khẩu?",
    submit: "ĐẶNG NHẬP",
    swap: "Bạn chưa phải thành viên, vui lòng ",
    or: "HOẶC",
    loginFB: "Đăng nhập bằng Facebook",
    signup: "Đăng ký",
    placeholder: {
      username: "Nhập email hoặc số điện thoại của bạn",
      password: "Tối thiệu 6 ký tự bao gồm chữ hoa, chữ thường và số",
    },
    validate: {
      blank: "Email hoặc số điện thoại không được để trống",
      blankPassword: "Mật khẩu không được để trống",
    },
  },
  forgotPassword: {
    title: "Khôi phục mật khẩu",
    username:
      "Email hoặc số điện thoại (chúng tôi sẽ gửi cho bạn mã OTP để đặt lại mật khẩu)",
    submit: "Gửi và xác nhận",
    placeholder: {
      username: "Nhập email hoặc số điện thoại của bạn",
    },
    validate: {
      blank: "Tài khoản không được để trống",
      send: "Gửi mã xác nhận không thành công",
    },
  },
  resetPassword: {
    newPassword: "Mật khẩu mới",
    reNewPassword: "Nhập lại mật khẩu mới",
    submit: "Đổi mật khẩu",
    placeholder: "Tối thiểu 6 ký tự bao gồm chữ hoa, chữ thường và số",
  },
  otpFrom: {
    title: "Mã xác thực",
    submit: "Xác nhận",
    resend: "Gửi lại",
    placeholder: "Nhập mã của bạn",
  },
  register: {
    seoTitle: "Đăng ký - ZeMor Coffee",
    title: "Đăng ký tài khoản",
    userName: "Email hoặc số điện thoại",
    fullName: "Họ và tên",
    password: "Mật khẩu",
    rePassword: "Nhập lại mật khẩu",
    role: "Tôi đã đọc và chấp nhận ",
    policy: "điều khoản",
    submit: "Đăng ký",
    swap: "Bạn đã là thành viên, vui lòng ",
    login: "Đăng nhập",
    linkPolicy: "/chinh-sach/chinh-sach-bao-mat",
    placeholder: {
      userName: "Nhập email hoặc số điện thoại của bạn",
      fullName: "Nhập họ và tên của bạn",
      password: "Tối thiểu 6 ký tự bao gồm chữ hoa, chữ thường và số",
      rePassword: "Xác nhận lại mật khẩu của bạn",
    },
    alert: {
      sendOtpSuccess: "Mã xác thực đã gửi đến ",
      sendOtpFailed: "Gửi lại mã xác thực không thành công",
      success: "Tạo tài khoản thành công! Đăng nhập để mua sắm ngay nào",
    },
    validate: {
      email: "Email hoặc số điện thoại không hợp lệ",
      blankPassword: "Mật khẩu không được để trống",
      password:
        "Mật khẩu có ít nhất 6 ký tự và ít nhất 1 chữ hoa, 1 chữ thường, 1 chữ số",
      password1: "Nhập lại mật khẩu không trùng khớp",
    },
  },
  cart: {
    cartInfo: "Thông tin đơn hàng",
    temp: "Tạm tính",
    ship: "Phí giao hàng",
    freeship: "Miễn phí giao hàng",
    total: "Tổng ước tính",
    pay: "Tiến hành thanh toán",
    choose: "Chọn ngay",
    avail: "Đang có hàng",
    typeShip: "Chọn đơn vị vận chuyển",
    accept: "Chấp nhận",
    voucher: "Mã giảm giá",
    chooseVoucher: "Nhập mã giảm giá của bạn",
  },
  noti: {
    title: "Thông báo",
  },
  store: {
    title: "Hệ thống cửa hàng",
  },
};
