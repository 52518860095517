import { useCartContext } from "@/setup/cartProvider";
import { useApiContext } from "@/setup/flexbiz-api-provider";
import { ActionIcon, Box, createStyles, NumberInput } from "@mantine/core";
import { AiOutlineDelete } from "@react-icons/all-files/ai/AiOutlineDelete";
import { FiMinus } from "@react-icons/all-files/fi/FiMinus";
import { FiPlus } from "@react-icons/all-files/fi/FiPlus";
import Context from "context/CartContext";
import { Numeral } from "numeral";
import { useContext, useEffect, useRef, useState } from "react";

const useStyles = createStyles((theme) => ({
  wrapper: {
    height: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: theme.radius.sm,
    border: `1px solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3]
    }`,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.white,

    "&:focus-within": {
      borderColor: theme.colors[theme.primaryColor][6],
    },
    "@media (max-width: 500px)": {
      justifyContent: "flex-start",
      width: 100,
      marginLeft: 10,
    },
  },

  control: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    border: `1px solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3]
    }`,

    "&:disabled": {
      borderColor:
        theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3],
      opacity: 0.8,
      backgroundColor: "transparent",
    },
  },

  input: {
    textAlign: "center",
    paddingRight: `${theme.spacing.xs}px !important`,
    paddingLeft: `${theme.spacing.xs}px !important`,
    height: 28,
    flex: 1,
  },
  container: {
    display: "flex",
    flex: 2,
    justifyContent: "space-between",
  },
  sectionDelete: {
    justifyContent: "center",
    "@media (min-width: 500px)": {
      display: "none",
      justifyContent: "flex-start",
      paddingLeft: 10,
    },
  },
}));

export function QuantityInput({ item, min = 0, max = item?.ton || 9999 }) {
  const context = useApiContext();

  const { classes } = useStyles();
  const handlers = useRef(null);

  // const [alert, setAlert] = useState({ open: false, message: "" });
  const [isShowMess, setIsShowMess] = useState(false);
  const [slXuat, setSlXuat] = useState(item?.sl_xuat);
  // console.log("slXuat", slXuat)
  const { updateCartMutation, dialogAction, deleteCart, updateCart } =
    useCartContext();

  const onOpen = (mess) => {
    context.alert(mess);
  };

  useEffect(() => {
    setSlXuat(item?.sl_xuat);
    // console.log(78787878, item.sl_xuat)
  }, [item, ]);
  const discount = item?.discount?.find(
    (e) => e.ma_cs === "ecompromotion_flashsales"
  );

  const deleteItemCart = async (item) => {
    dialogAction({
      title: "Xóa sản phẩm",
      message: "Bạn có chắc chắn muốn xóa sản phẩm này khỏi giỏ hàng?",
      onConfirm: async () => {
        // console.log("log item", item);
        deleteCart(item);
      },
    });
  };

  const onMinus = async () => {
    if (item.sl_xuat - 1 === 0) {
      deleteItemCart(item);
      return;
    }
    if (
      item?.discount.length &&
      slXuat <= item?.discount[0]?.sl_ap_dung &&
      isShowMess
    ) {
      setIsShowMess(false);
    }
    updateCartMutation.mutate({
      _id: item._id,
      sl_xuat: item.sl_xuat - 1,
    });
  };

  const onPlus = async () => {
    if (slXuat >= item.ton && Number(item.ton) > 0) {
      setSlXuat(Number(item.ton));
      updateCartMutation.mutate({
        _id: item._id,
        sl_xuat: Number(item.ton),
      });
      return;
    }
    if (slXuat - discount?.sl_ap_dung === 1 && discount?.sl_ap_dung != 0) {
      setIsShowMess(true);
      const mess = `Giá bán này giới hạn mua tối đa ${
        item.sl_ap_dung ? item.sl_ap_dung : item?.discount[0]?.sl_den
      } sản phẩm, giá bán sẽ thay đổi thành ${Numeral(
        item.gia_ban_le
      ).format()}đ`;
      onOpen(mess);
    }
    updateCartMutation.mutate({
      _id: item._id,
      sl_xuat: item.sl_xuat + 1,
    });
  };

  const onQuantityChange = async () => {
    if (slXuat > item.ton && Number(item.ton) > 0) {
      context.alert("Số lượng bạn chọn lớn hơn sản phẩm tồn kho");
      setSlXuat(Number(item.ton));
      updateCart({
        _id: item._id,
        sl_xuat: Number(item.ton),
      });
      return;
    }
    if (slXuat === 0) {
      setSlXuat(1);
      deleteItemCart(item);
      return;
    }
    if (slXuat + 1 > item.sl_ap_dung && !isShowMess) {
      setIsShowMess(true);
      const mess = `Giá bán này giới hạn mua tối đa ${
        item.sl_ap_dung ? item.sl_ap_dung : item?.discount[0]?.sl_den
      } sản phẩm, giá bán sẽ thay đổi thành ${Numeral(
        item.gia_ban_le
      ).format()}đ`;
      // onOpen(mess);
      context.alert(mess);
    }
    if (
      item.discount.length &&
      item.discount[0].sl_tu !== 0 &&
      slXuat < item.discount[0].sl_tu &&
      isShowMess
    ) {
      setIsShowMess(false);
    }
    updateCart({
      _id: item._id,
      sl_xuat: slXuat,
    });
    
    updateCartMutation.mutate({
      _id: item._id,
      sl_xuat: item.sl_xuat,
    });
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.wrapper}>
        <ActionIcon
          size={28}
          variant="transparent"
          onClick={onMinus}
          disabled={slXuat === min || updateCartMutation.isLoading}
          className={classes.control}
          onMouseDown={(event) => event.preventDefault()}
        >
          <FiMinus size={16} />
        </ActionIcon>
        <NumberInput
          sx={{ width: "fit-content" }}
          variant="unstyled"
          min={min}
          max={max}
          handlersRef={handlers}
          value={slXuat}
          onChange={setSlXuat}
          classNames={{ input: classes.input }}
          onBlur={onQuantityChange}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              onQuantityChange();
            }
          }}
        />
        <ActionIcon
          size={28}
          variant="transparent"
          onClick={onPlus}
          disabled={slXuat === max || updateCartMutation.isLoading}
          className={classes.control}
          onMouseDown={(event) => event.preventDefault()}
        >
          <FiPlus size={16} />
        </ActionIcon>
      </Box>
      <Box sx={classes.sectionDelete}>
        <ActionIcon onClick={() => deleteCart(item)}>
          <AiOutlineDelete variant="transparent" size={22} />
        </ActionIcon>
      </Box>
    </Box>
  );
}
