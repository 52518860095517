import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    background: "#efefef",
    width: "100%",
  },

  logoWrapper: {
    position: "relative",
    aspectRatio: "2",
    height: "50px",
    marginBottom: "10px",
    // width: "122px",
    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
      height: "40px",
    },
  },

  // Subscribe
  subscribe: {
    position: "relative",

    minWidth: "100%",
    // aspectRatio: "8.3",
    height: 161,

    // "&:before": {
    //   background: `url('${bg.src}') no-repeat center`,
    //   backgroundSize: "cover",

    //   content: "''",
    //   display: "block",
    //   position: "absolute",
    //   left: 0,
    //   top: 0,
    //   width: "100%",
    //   height: "100%",
    //   opacity: "0.4",
    // },
  },
  bgSubscribe: {
    overflow: "hidden",
    minWidth: "100%",
    // aspectRatio: "8.37",
    height: 161,
    opacity: "0.4",
    display: "block",
    position: "absolute",
    left: 0,
    top: 0,
  },
  subscribeContent: {
    position: "relative",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  // Info
  info: {
    display: "flex",
    flexDirection: "column",

    minWidth: "100%",
    background: "#1D2128",
    color: "#DEE1E6",
  },
  infoBody: {
    width: "100%",
    padding: "30px 0 20px",
  },
  // logoWrapper: {
  //   position: "relative",
  //   aspectRatio: "3.05",
  //   height: "50px",
  //   width: "152px",
  //   marginBottom: "10px",
  // },
  infoText: {
    transition: "0.3s",
    "&:hover": {
      color: theme.colors.main[5],
    },
  },
  infoText1: {
    "&::-moz-selection": {
      /* Code for Firefox */
      background: "#000",
    },

    "&::selection": {
      background: "#000",
    },
  },
  infoBottom: {
    padding: "20px 0 25px",

    color: "#999999",
    fontWeight: "400",
    fontSize: 14,
  },
}));

export default useStyles;
