const title = "ZeMor Coffee";
const description = "";

const defaultSEOConfigs = {
  titleTemplate: "%s | Be great & more",
  defaultTitle: title,
  title,
  //
  description,
  openGraph: {
    type: "website",
    url: "https://zemor.vn/",
    title,
    description,
    locale: "vi",
    site_name: "ZeMor",
    images: [
      {
        url: "https://api.flexzen.app/api/617029ff29b9fb21e16741bb/file/download/64143197713e21140283a000?size=S&access_token=5d317ff07b8baef29f3230a69fe9d617",
        width: 315,
        height: 160,
        alt: "ZeMor logo",
      },
    ],
  },
};

export default defaultSEOConfigs;
